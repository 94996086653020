.CircularProgressbar-path {
    stroke: rgb(52, 211, 153) !important;
}

.CircularProgressbar-text {
    fill: #444 !important;
    font-size: 1.5rem !important;
    margin-top: 5px !important;
}

.CircularProgressbar .CircularProgressbar-trail {
    /* stroke: #9c9c9c52!important; */

    stroke: rgb(252, 88, 123) !important;
}
