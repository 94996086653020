.shepherd-button {
    background: #34d399;
    border: 0;
    border-radius: 0.75rem;
    color: rgba(0, 48, 36, 1);
    cursor: pointer;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
    height: 3rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    font-family: inherit;
    font-size: 100%;
    font-weight: 600;
    width: 100px;
    min-width: 100px;
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.shepherd-button:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.shepherd-button:not(:disabled):hover {
    background: #111827;
    color: #f9fafb;
}
.shepherd-button.shepherd-button-secondary {
    background: #f3f4f6;
    color: #4b5563;
}

.shepherd-button.shepherd-button-third {
    background: #a7f3d0;
    color: #047857;
}
.shepherd-button.shepherd-button-secondary:not(:disabled):hover {
    background: #111827;
    color: #f9fafb;
}
.shepherd-button:disabled {
    cursor: not-allowed;
}
.shepherd-footer {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    justify-content: flex-end;
    padding: 0 0.75rem 0.75rem;
}
.shepherd-footer .shepherd-button:last-child {
    margin-right: 0;
}
.shepherd-cancel-icon {
    background: transparent;
    border: none;
    color: #a7f3d0;
    font-size: 1.75em;
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    padding: 0;
    transition: color 0.5s ease;
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.shepherd-cancel-icon:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.shepherd-cancel-icon:hover {
    color: rgba(0, 0, 0, 0.75);
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
    color: #37415166;
}
.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
    color: rgba(0, 0, 0, 0.75);
}
.shepherd-title {
    color: #047857;
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    flex: 1 0 auto;
    margin: 0;
    padding: 0;
}
.shepherd-header {
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    justify-content: flex-end;
    line-height: 2em;
    padding: 0.75rem 2rem 0;
}
.shepherd-has-title .shepherd-content .shepherd-header {
    background: #ecfdf5;
    padding: 2em;
}
.shepherd-text {
    color: #1f2937;
    font-size: 0.9rem;
    line-height: 2em;
    padding: 3em;
}

.video-player .shepherd-text {
    width: 800px;
    height: 600px;
    margin: auto;
}

@supports (aspect-ratio: auto) {
    .video-player .shepherd-text {
        width: 100%;
        aspect-ratio: 16 / 9;
    }
}
.shepherd-text p {
    margin-top: 0;
}
.shepherd-text p:last-child {
    margin-bottom: 0;
}
.shepherd-content {
    border-radius: 15px;
    outline: none;
    padding: 0;
}

.shepherd-element {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    /* width: 500px; */
    max-width: 500px;
    margin: 2em !important;
    opacity: 0;
    outline: none;
    transition: opacity 0.3s, visibility 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
}

.shepherd-element.video-player {
    max-width: 1024px !important;
    width: 1024px !important;
    height: 760px !important;
}

@supports (aspect-ratio: auto) {
    .shepherd-element.video-player {
        width: 1024px !important;
        aspect-ratio: 16 / 9;
    }
}

.shepherd-enabled.shepherd-element {
    opacity: 1;
    visibility: visible;
}
.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}
.shepherd-element,
.shepherd-element *,
.shepherd-element :after,
.shepherd-element :before {
    box-sizing: border-box;
}
.shepherd-arrow,
.shepherd-arrow:before {
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: -1;
}
.shepherd-arrow:before {
    content: '';
    transform: rotate(45deg);
    background: #fff;
}
.shepherd-element[data-popper-placement^='top'] > .shepherd-arrow {
    bottom: -8px;
}
.shepherd-element[data-popper-placement^='bottom'] > .shepherd-arrow {
    top: -8px;
}
.shepherd-element[data-popper-placement^='left'] > .shepherd-arrow {
    right: -8px;
}
.shepherd-element[data-popper-placement^='right'] > .shepherd-arrow {
    left: -8px;
}
.shepherd-element.shepherd-centered > .shepherd-arrow {
    opacity: 0;
}
.shepherd-element.shepherd-has-title[data-popper-placement^='bottom']
    > .shepherd-arrow:before {
    background-color: #e6e6e6;
}
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
    pointer-events: none;
}
.shepherd-modal-overlay-container {
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
    width: 100vw;
    z-index: 9997;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible {
    height: 100vh;
    opacity: 0.5;
    transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
    pointer-events: all;
}
