/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .tour-highlight-1 {
        @apply border-4 border-green-400;
    }
    .marketing-title {
        @apply whitespace-normal text-green-400 font-bold text-2xl tracking-tight leading-none;
    }
}

@layer base {
    .block-html ul,
    .block-html ol {
        @apply ml-4 text-xs mb-8;
    }
    .block-html ul li {
        @apply list-disc;
    }
    .block-html ol li {
        @apply list-decimal;
    }
    .block-html li:not(:last-child) {
        @apply mb-1;
    }
    .block-html a {
        @apply text-green-500 underline;
    }
    .block-html a:hover {
        @apply text-green-900;
    }
    .block-html p {
        @apply mb-4;
    }

    .block-html.center-text p {
        @apply text-center;
    }

    .block-html h1 {
        @apply md:-ml-4 w-min whitespace-nowrap font-semibold text-sm md:text-base px-3 py-1 rounded-lg bg-green-100 text-green-600 mb-4;
    }
    .block-html h2 {
        @apply w-min whitespace-nowrap font-semibold tracking-wide text-xs px-3 py-1 rounded-lg uppercase bg-blue-100 text-blue-600 mb-4;
    }

    .kn-include.block-html h1 {
        @apply md:-ml-4 w-min whitespace-nowrap font-semibold text-sm md:text-base px-3 py-1 rounded-lg bg-green-100 text-green-600 mb-4;
    }
    .kn-include.block-html h2 {
        @apply w-min whitespace-nowrap font-semibold tracking-wide text-xs px-3 py-1 rounded-lg uppercase bg-blue-100 text-blue-600 mb-4;
    }

    .kn-exclude.block-html h1 {
        @apply md:-ml-4 w-min whitespace-nowrap font-semibold text-sm md:text-base px-3 py-1 rounded-lg bg-red-100 text-red-600 mb-4;
    }
    .kn-exclude.block-html h2 {
        @apply w-min whitespace-nowrap font-semibold tracking-wide text-xs px-3 py-1 rounded-lg uppercase bg-blue-100 text-blue-600 mb-4;
    }
}

@layer utilities {
    @variants responsive {
        /* Chrome, Safari and Opera */

        .x1 {
            position: relative;
            flex: 0 0 100%;
            margin-right: 0px;
        }

        .x2 {
            position: relative;
            flex: 0 0 50%;
            margin-right: 0px;
        }
        .x3 {
            position: relative;
            flex: 0 0 33.33%;
            margin-right: 0px;
        }
        .x4 {
            position: relative;
            flex: 0 0 25%;
            margin-right: 0px;
        }

        .x5 {
            position: relative;
            flex: 0 0 20%;
            margin-right: 0px;
        }

        .x6 {
            position: relative;
            flex: 0 0 16.67%;
            margin-right: 0px;
        }

        .x7 {
            position: relative;
            flex: 0 0 14.2%;
            margin-right: 0px;
        }

        .z-100 {
            z-index: 100 !important;
        }

        .z-200 {
            z-index: 200 !important;
        }

        .z-1000 {
            z-index: 1000 !important;
        }

        .video-player-16\/9 {
            width: 668px;
            height: 375px;
        }

        .video-player-16\/9-md {
            width: 540px;
            height: 303px;
        }

        .video-player-1\/1 {
            width: 100%;
            height: 90vw;
        }

        .video-player-9\/16 {
            width: 100%;
            height: 160vw;
        }

        .video-player-4\/5 {
            width: 100%;
            height: 112vw;
        }

        @supports (aspect-ratio: auto) {
            .video-player-16\/9 {
                width: 100%;
                aspect-ratio: 16 / 9;
                height: unset;
            }

            .video-player-16\/9-md {
                width: 100%;
                aspect-ratio: 16 / 9;
                height: unset;
            }

            .video-player-1\/1 {
                width: 100%;
                aspect-ratio: 1 / 1;
                height: unset;
            }

            .video-player-9\/16 {
                width: 100%;
                aspect-ratio: 9 / 16;
                height: unset;
            }

            .video-player-4\/5 {
                width: 100%;
                aspect-ratio: 4 /5;
                height: unset;
            }
        }

        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        .no-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }

        .glass-panel-xl {
            width: 1300px;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -650px;
            margin-right: -650px;
        }

        .glass-panel-lg {
            width: 850px;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -425px;
            margin-right: -425px;
        }

        .glass-panel-md {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
        }

        .glass-panel-sm {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
        }

        .full-width {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
        }

        .full-width-left-xl {
            margin-left: calc(50% - 448px);
            margin-right: calc(50% - 448px);
        }

        .full-width-left-lg {
            margin-left: calc(50% - 338px);
        }

        .full-width-left-md {
            margin-left: calc(50% - 270px);
        }

        .full-width-left-sm {
            margin-left: calc(50% - 160px);
        }

        .kn-rtl {
            direction: rtl;
            text-align: right;
        }

        .kn-ltr {
            direction: ltr !important;
            text-align: left !important;
        }
    }

    .rainbow-gradient {
        background-image: linear-gradient(
            74deg,
            rgba(196, 181, 253, 1) 0%,
            rgba(147, 197, 253, 1) 19%,
            rgba(110, 231, 183, 1) 43%,
            rgba(255, 243, 65, 1) 65%,
            rgba(252, 211, 77, 1) 79%,
            rgba(252, 165, 165, 1) 92%
        );
    }
    .landing-gradient {
        background: rgb(178, 212, 197);
        background-image: linear-gradient(0deg, rgba(178, 212, 197, 1) 0%, rgba(236, 253, 245, 1) 100%);
    }

    .landing-gradient-dark {
        background: rgb(54, 90, 84);
        background-image: linear-gradient(0deg, rgba(54, 90, 84, 1) 0%, rgba(52, 85, 80, 1) 83%, rgba(41, 70, 65, 1) 100%);
    }

    .underline-thick-current {
        text-decoration-color: currentColor !important;
        text-decoration: underline;
        text-decoration-thickness: 7px;
    }

    .underline-thick {
        text-decoration-color: #00cc97 !important;
        text-decoration: underline;
        text-decoration-thickness: 7px;
    }

    .relative-forced {
        position: relative !important;
    }
    .static-forced {
        position: static !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
}

@font-face {
    text-rendering: optimizeLegibility;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    background: #d1d1d1;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #969696;
}
/* ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #fcfcfc;
} */

::-webkit-scrollbar-track {
    background: transparent;
    /* border-radius: 10px; */
    /* box-shadow: inset 7px 10px 12px #fcfcfc; */
}

.ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
}

.ql-align-right {
    text-align: right !important;
}

.ql-container.ql-snow {
    border: none !important;
    height: auto;
}
.comment .ql-editor {
    height: 225px !important;
    overflow-y: scroll !important;
    padding: 0px !important;
}

@media (max-width: 640px) {
    .comment .ql-editor {
        height: 400px !important;

        /* padding: 0px 16px !important; */
    }
}

.ql-toolbar.ql-snow {
    border: none !important;
    box-sizing: border-box;
    font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif !important;
    padding: 12px 0px 16px !important;

    /* display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center !important; */
}

.ql-container {
    font-family: 'Poppins', 'Tajawal', sans-serif !important;
    font-size: 15px !important;
}

.ql-rtl .ql-container {
    font-size: 18px !important;
}

p > a {
    color: #00cc97 !important;
    text-decoration: underline !important;
}

.ql-rtl .ql-toolbar.ql-snow {
    direction: rtl !important;
    text-align: right !important;
}

.ql-rtl .ql-toolbar.ql-snow button {
    float: right !important;
}

.ql-editor.ql-blank::before {
    left: 10px !important;
    /* position: unset !important; */
    right: unset !important;
}

.ql-rtl .ql-editor.ql-blank::before {
    left: unset !important;

    right: 10px !important;
    direction: rtl !important;
}

.ql-editor ul,
.ql-editor ol {
    font-size: 0.75rem !important;
    margin-bottom: 2rem !important;
    margin-left: 1rem !important;
    padding-left: 0 !important;
}

.ql-editor p {
    margin-bottom: 1rem !important;
}

.ql-editor li:not(:last-child) {
    margin-bottom: 0.25rem !important;
}

.ql-editor h1 {
    background-color: #e5e7eb !important;
    border-radius: 0.5rem;
    font-weight: 700 !important;
    font-size: 1.125rem !important;
    margin-bottom: 1rem !important;
    margin-left: -1rem !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    color: #374151 !important;
    white-space: nowrap !important;
    width: min-content !important;
}

.ql-editor h2 {
    background-color: #f3f4f6;
    border-radius: 0.5rem !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    margin-bottom: 1rem !important;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    color: #374151 !important;
    text-transform: uppercase !important;
    letter-spacing: 0.1em !important;
    white-space: nowrap !important;
    width: min-content !important;
}

.kn-blur {
    backdrop-filter: blur(5px) !important;
}

.Modal {
    position: absolute;
    /* top: 200px;
    left: 200px;
    right: 200px;
    bottom: 200px; */
    background-color: white;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    backdrop-filter: blur(5px);
}

[data-reach-combobox-popover] {
    border: none !important;
    background: hsla(0, 100%, 100%, 0.99);
    font-size: unset !important;
}

.tuts .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.overflow-scrolling {
    overflow-y: scroll;
    /* -webkit-overflow-scrolling: touch; */
}
